var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ErrorLogSettingsStyled', [_c('span', [_vm._v(_vm._s(_vm.$t('errorStatisticsSettings.select')) + ":")]), _c('CustomMultiSelect', {
    staticClass: "filter-dropdown",
    attrs: {
      "track-by": "id",
      "openDirection": "bottom",
      "value": _vm.SelectedErrorTypeOption,
      "options": _vm.options,
      "shouldNotResetSelectedOption": true,
      "closeOnSelect": true,
      "searchable": false,
      "multiple": true,
      "maxHeight": 256,
      "applyFilter": false,
      "internalSearch": false,
      "allowEmpty": false
    },
    on: {
      "select": function select($event) {
        return _vm.setErrorTypeOption($event);
      },
      "remove": function remove($event) {
        return _vm.setErrorTypeOption($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }