<template>
  <ErrorStatisticsSettingsStyled>
    <ErrorTypeFilterMolecule :widgetSettings="widgetSettings" @changeWidgetSettings="$emit('changeWidgetSettings', $event)" />
  </ErrorStatisticsSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ErrorTypeFilterMolecule from '@/components/Atomic/Molecules/ErrorTypeFilterMolecule'
const ErrorStatisticsSettingsStyled = styled('div')``

export default {
  components: {
    ErrorStatisticsSettingsStyled,
    ErrorTypeFilterMolecule,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
  },
}
</script>
