<template>
  <ErrorLogSettingsStyled>
    <span>{{ $t('errorStatisticsSettings.select') }}:</span>
    <CustomMultiSelect
      class="filter-dropdown"
      track-by="id"
      openDirection="bottom"
      :value="SelectedErrorTypeOption"
      :options="options"
      :shouldNotResetSelectedOption="true"
      :closeOnSelect="true"
      :searchable="false"
      :multiple="true"
      :maxHeight="256"
      :applyFilter="false"
      :internalSearch="false"
      :allowEmpty="false"
      @select="setErrorTypeOption($event)"
      @remove="setErrorTypeOption($event)"
    >
    </CustomMultiSelect>
  </ErrorLogSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import localesMixin from '@/mixins/locales'
import { CustomMultiSelect } from '@common/components'
import chroma from 'chroma-js'

const ErrorLogSettingsStyled = styled('div')`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  gap: 0.5rem;
  > span {
    display: flex;
    align-items: center;
  }
  > .filter-dropdown {
    max-width: 15rem;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => chroma(theme.colors.primary).alpha(0.5).css()};
  }
`
export default {
  mixins: [localesMixin],
  components: {
    ErrorLogSettingsStyled,
    CustomMultiSelect,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      SelectedErrorTypeOption: [],
    }
  },
  mounted() {
    this.initDefaultValues(this.widgetSettings)
  },
  computed: {
    options() {
      return [
        {
          id: 1,
          label: 'J1939',
          name: 'J1939_NEW',
        },
        {
          id: 2,
          label: 'OEM',
          name: 'OEM',
        },
      ]
    },
  },
  methods: {
    setErrorTypeOption(value) {
      if (this.SelectedErrorTypeOption.includes(value)) {
        this.SelectedErrorTypeOption = this.SelectedErrorTypeOption.filter(option => option.id !== value.id)
      } else {
        this.SelectedErrorTypeOption.push(value)
      }
      const errorLogSettings = {
        errorTypeName: { set: this.SelectedErrorTypeOption.map(a => a.name) },
      }
      this.$emit('changeWidgetSettings', { ...errorLogSettings })
    },
    initDefaultValues(widgetSettings) {
      if (!widgetSettings?.errorTypeName?.length) {
        this.SelectedErrorTypeOption = this.options
      } else {
        this.SelectedErrorTypeOption = widgetSettings?.errorTypeName.map(errorTypeName =>
          this.options.find(option => option.name === (errorTypeName === 'GHH' ? 'OEM' : errorTypeName)),
        )
      }
    },
  },
}
</script>
